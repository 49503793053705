import { useState, useEffect } from 'react';
import {
  graphqlAccessQuery,
  graphqlTicketQuery,
  graphqlEventsByRestaurantsQuery,
} from '@/lib/api.ts';
import { useNavigate } from 'react-router-dom';
import { AuthAtom } from '../lib/state.ts';
import { useAtomValue } from 'jotai';
import styles from './AccessType.module.css';
import {
  useWidgetState,
  UserAtom,
  useRestaurantId,
  useRestaurant,
  RestaurantAtom,
} from '../lib/state.ts';
import { isPaymentReady } from '../lib/ready.ts';
import { LuChevronLeft } from 'react-icons/lu';
import dayjs from "dayjs";

export function AccessTypes() {
  const [screen, setScreen] = useState(0);
  const [eventId, setEventId] = useState();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const id = useRestaurantId();
  const user = useAtomValue(UserAtom);
  const auth = useAtomValue(AuthAtom);
  const { meta } = useRestaurant();
  const restaurant = useAtomValue(RestaurantAtom);
  const [accessTypes, setAccessTypes] = useState([]);
  const [ticketInventory, setTicketInventory] = useState([]);
  const [widget, updateWidget] = useWidgetState();
  const navigate = useNavigate();
  const user_ready = !!auth?.token && !!user?.phone;
  const payment_ready = user_ready && isPaymentReady(widget);

  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const eventInfo = await graphqlEventsByRestaurantsQuery(restaurant?.id);

        const getEventId =
          eventInfo?.data?.getEventsByRestaurantId &&
          eventInfo?.data?.getEventsByRestaurantId?.length > 0
            ? eventInfo.data.getEventsByRestaurantId[0].id
            : null;

        setEventId(getEventId);

        if (eventInfo.data.getEventsByRestaurantId.length > 0) {
          setEvents(eventInfo.data.getEventsByRestaurantId);
        }

      } catch (err) {
        console.log('Error fetching event info', err);
        setIsLoading(false);
      }
    };

    fetchRestaurant()
      .then()
      .catch((err) => console.log('err', err));
  }, []);

  useEffect(() => {
    const fetchAccessTypes = async () => {
      try {
        const accessTypeQuery = await graphqlAccessQuery(eventId);

        if (accessTypeQuery && accessTypeQuery.data) {
          setAccessTypes(accessTypeQuery.data.accessTypes);
          setIsLoading(false);
        }
      } catch (err) {
        console.log('Error fetching access types', err);
      }
    };

    fetchAccessTypes()
      .then()
      .catch((err) => {
        console.log('error', err);
        setIsLoading(false);
      });
  }, [eventId]);

  async function handleAccessType({ item }) {
    if (!item.id) {
      return;
    }

    try {
      const ticketQuery = await graphqlTicketQuery(eventId, item.id);

      setTicketInventory(ticketQuery.data.ticketInventory);
      setScreen(1);
    } catch (err) {
      console.log('Error fetching access types', err);
    }
  }

  function handleTicketOption({ ticketItem, count = 1 }) {
    if (!ticketItem) {
      return;
    }

    if (ticketItem.sold_out) {
      return;
    }

    updateWidget({
      confirm_body: {
        restaurant_id: id!,
        seating_id: ticketItem.id,
        party: ticketItem.guests,
      },
      date: '2024-07-04',
      availability: meta?.availability[0],
      option: {
        price__currency: ticketItem.price__currency,
        price: ticketItem.price,
        seats: ticketItem.guests,
        seating_id: ticketItem.id,
        type: null,
      },
      event: {
        id: eventId,
      },
      eventInfo: {
        id: eventId,
        ticektItemId: ticketItem.id,
      },
    });

    switch (true) {
      case payment_ready:
        navigate('../eventconfirm');
        break;
      case user_ready:
        navigate('../eventpayment');
        break;
      default:
        // Weird place for this, but make sure we don't have some old payment method set for a different user
        updateWidget({
          payment_method: undefined,
        });
        navigate('../eventinfo');
        break;
    }
  }

  if (ticketInventory.length > 0 && screen === 1) {
    return (
        <div>
          <div className={styles.chooseSection}>
            <div onClick={() => setScreen(0)} className={styles.back}>
              <LuChevronLeft /> Back
            </div>

            {ticketInventory.map((ticket, index) => {
              return (
                  <div
                      key={ticket.id}
                      className={styles.accessItem}
                      onClick={() => {
                        handleTicketOption({ ticketItem: ticket });
                      }}
                  >
                    <div className={styles.info}>{ticket.item.name}</div>
                    <div>{ticket.guests} guest{ticket.guests !== 1 ? 's' : ''}</div>
                    <div>{ticket.price__currency}</div>
                    <div>{ticket.sold_out ? 'Sold Out' : ''}</div>
                  </div>
              );
            })}
          </div>
        </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.chooseSection}>
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
      <div style={{ width: '100%' }}>
        <h3>Select Date</h3>

        <div style={{ display: 'flex', flex: 'auto', flexDirection: 'row', width: '100%', paddingBottom: '20px', justifyContent: 'flex-start', alignItems: 'center', overflow: 'auto', minWidth: '100%', maxWidth: '100%', borderBottom: '1px solid #ddd' }}>
          {events.map((event) => {
            return <button onClick={() => setEventId(event.id)} className={ eventId === event.id ? styles.eventNameSelected : styles.eventName }>
              <span style={{ fontSize: '1rem' }}>{ dayjs(event.date).format('ddd, MMM D') }</span>
            </button>
          })}
        </div>

        <div>
          {  accessTypes.length === 0 && <div className={styles.chooseSection}>
            <h2>Sorry, there are no tickets available for this event.</h2>
          </div>}
        </div>

        <h3>Select Type</h3>

        <div className={styles.chooseSection}>
          {accessTypes.map((acc) => {
            return (
                <div
                    key={acc.id}
                    className={styles.accessItem}
                    onClick={() => {
                      handleAccessType({ item: acc });
                    }}
                >
                  <h2>{acc.name}</h2>
                  <div className={styles.info}>{acc.guest_summary}</div>
                  <div className={styles.description}>{acc.description}</div>
                  <div>{acc.sold_out ? 'sold out' : ''}</div>
                </div>
            );
          })}
        </div>
      </div>
  );
}
