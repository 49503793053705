import clsx from 'clsx';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { Suspense, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../Header/Header.tsx';
import RestaurantName, {
  RestaurantNameLoader,
} from '../RestaurantName/RestaurantName.tsx';
import {
  useWidgetState,
  useRestaurantId,
  useRestaurant,
  UserAtom,
  RestaurantAtom,
  AuthAtom,
} from '../lib/state.ts';

import { isBookingReady, isPaymentReady } from '../lib/ready.ts';
import NeedToKnows from '../../../components/NeedToKnows/NeedToKnows.tsx';

import {
  ReservationDates,
  ReservationDatesLoader,
} from './ReservationDates.tsx';
import {
  ReservationTimes,
  ReservationTimesLoader,
} from './ReservationTimes.tsx';
import ReservationTables from './ReservationTables.tsx';
import 'react-phone-number-input/style.css';
import styles from './Booking.module.css';
import { AccessTypes } from './AccessTypes.tsx';

const Booking: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = useRestaurantId();
  const navigate = useNavigate();
  const [widget, updateWidget] = useWidgetState();
  const booking_ready = isBookingReady(widget);
  const user = useAtomValue(UserAtom);
  const auth = useAtomValue(AuthAtom);
  const restaurant = useAtomValue(RestaurantAtom);
  const user_ready = !!auth?.token && !!user?.phone;
  const payment_ready = user_ready && isPaymentReady(widget);
  const isEvent = restaurant?.type === 'event';
  const code = searchParams.get('code');
  const setUser = useSetAtom(UserAtom);

  const onNext = () => {
    updateWidget({
      payment_error: undefined,
    });
    if (widget.option && widget.availability && restaurant) {
      // update for local
      const date_restaurant = dayjs(widget.availability?.datetime_utc).tz(
        restaurant.timezone
      );

      const dateFormatted = date_restaurant
        ? date_restaurant?.format('YYYY-MM-DD')
        : '';

      updateWidget({
        confirm_body: {
          restaurant_id: id!,
          seating_id: widget.option.seating_id,
          party: widget.option.seats,
          date: dateFormatted,
          time: widget.availability.time,
          skip_level_check: true,
        },
        referral_code: code,
      });
    }

    switch (true) {
      case payment_ready:
        navigate('../confirm');
        break;
      case user_ready:
        navigate('../payment');
        break;
      default:
        // Weird place for this, but make sure we don't have some old payment method set for a different user
        updateWidget({
          payment_method: undefined,
        });
        navigate('../info');
        break;
    }
  };

  useEffect(() => {
    if (code) {
      setUser({
        referral_code: code,
      });

      updateWidget({
        referral_code: code,
      });
    }
  }, [code]);

  return (
    <>
      <Header title={ isEvent ? 'Make a booking' : 'Book a reservation'} step={1} />

      <div className="content">
        <Suspense fallback={<RestaurantNameLoader />}>
          <RestaurantName />
        </Suspense>

        {isEvent ? (
          <AccessTypes />
        ) : (
          <>
            {widget?.reservation_error && (
              <div className={clsx('content', styles.header_error)}>
                {widget?.reservation_error}
              </div>
            )}

            <div className={clsx('section', styles.section)}>
              <h3>Select a date</h3>

              <Suspense fallback={<ReservationDatesLoader />}>
                <ReservationDates />
              </Suspense>
            </div>

            {!!widget.date && (
              <div className={clsx('section', styles.section)}>
                <h3>Select a time</h3>

                <Suspense fallback={<ReservationTimesLoader />}>
                  <ReservationTimes key={widget.date} />
                </Suspense>
              </div>
            )}

            {!!widget.date && !!widget.availability && (
              <div className={clsx('section', styles.section)}>
                <h3>Select a table</h3>

                <Suspense fallback={<ReservationTimesLoader />}>
                  <ReservationTables
                    key={`${widget.date}-${widget.availability.uid}`}
                  />
                </Suspense>
              </div>
            )}
          </>
        )}

        {!isEvent && (
          <div className="section">
            <button
              className="nextButton"
              onClick={onNext}
              disabled={!booking_ready}
            >
              Next
            </button>
          </div>
        )}

        <div className={clsx('section', styles.needtoknow)}>
          <h3>Need to know</h3>

          <Suspense fallback={<Loading />}>
            <NTKs />
          </Suspense>
        </div>
      </div>
    </>
  );
};

const NTKs: React.FC = () => {
  const { restaurant } = useRestaurant();

  if (!restaurant) {
    return <></>;
  }

  return <NeedToKnows need_to_knows={restaurant.need_to_knows} />;
};

const Loading: React.FC = () => {
  return <div>Wait...</div>;
};

export default Booking;
