import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Card, { CardNames } from '../../../components/Card/Card.tsx';
import Header from '../Header/Header.tsx';
import { claimReservation, getUserCredits, getReservationConfirmation } from '../../../lib/api.ts';
import { ApiError } from '../../../lib/ApiError.ts';
import RemainingTime from '../RemainingTime/RemainingTime.tsx';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  AuthAtom,
  PaymentMethodsByIdAtom,
  ReservationAtom,
  useDirectLinkState,
} from '../lib/state.ts';

import styles from './Confirm.module.css';
import Config from '@/lib/Config.ts';
import dayjs from "dayjs";
import {useSetAtom} from "jotai/index";

const Confirm: React.FC = () => {
  const navigate = useNavigate();
  const [booking, setBooking] = useState<boolean>(false);
  const auth = useAtomValue(AuthAtom);
  const reservation = useAtomValue(ReservationAtom);
  const setReservation = useSetAtom(ReservationAtom);
  const [directLink, updateDirectLink] = useDirectLinkState();
  const methods_by_id = useAtomValue(PaymentMethodsByIdAtom);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [creditsAvailable, setCreditsAvailable] = useState<boolean>(false);
  const [useCredits, setUseCredits] = useState<boolean>(false);

  useEffect(() => {
    const waitForIt = async () => {
      setStripe(await loadStripe(Config.data.stripe.key));
    };
    void waitForIt();

    if (reservation?.claimed_at && ! reservation?.confirmed_at) {
      navigate(`../processing_payment/`);
    }

    void onCheckCredits();
  }, [reservation]);

  const onCheckCredits = async () => {
    const user_credits_response = await getUserCredits(auth!.token);
    if (!user_credits_response) {
      return;
    }
    const hasCredits = user_credits_response.data.credits.USD !== "$0.00";
    const hasPoints = user_credits_response.data.points > 0;

    setCreditsAvailable(hasCredits || hasPoints);

    if ((hasCredits || hasPoints) && (reservation!.credits_used || reservation!.points_used)) {
      setUseCredits(true);
    }
  };

  const onConfirm = async (useCreditsValue: boolean) => {
    setUseCredits(useCreditsValue);

    const response = await getReservationConfirmation(
        auth!.token,
        reservation!.restaurant.id,
        {
          restaurant_id: reservation!.restaurant.id,
          seating_id: reservation!.seating_id,
          party: reservation!.party_size,
          date: dayjs(reservation!.reservation_at_tzd).format('YYYY-MM-DD'),
          time: reservation!.reservation_time_pretty,
          use_credits: useCreditsValue,
          skip_level_check: true,
        }
    );

    if (response) {
      setReservation((prev) => ({
        ...prev!,
        total_paid: response.reservation.total,
        total_paid__currency: response.reservation.total__currency,
        credits_used: response.reservation.credits_used,
        credits_used__currency: response.reservation.credits_used__currency,
        points_used: response.reservation.points_used,
        points_used_currency_value__currency: response.reservation.points_used_currency_value__currency,
      }));
    }
  };

  const onNext = () => {
    if (!auth) {
      return;
    }

    if (!directLink.claim_body && (!reservation || reservation.total_paid > 0)) {
      // claim_body may be missing when total_paid is 0
      return;
    }

    const go = async () => {
      setBooking(true);

      if (!directLink.claim_body && (!reservation || reservation.total_paid > 0)) {
        // claim_body may be missing when total_paid is 0
        return;
      }

      if (reservation!.sold_out) {
        navigate(`../unavailable/`);
        return;
      }

      if (reservation!.cancelled) {
        navigate(`../expired/`);
        return;
      }

      try {
        const claimBodyWithCredits = {
          ...directLink.claim_body!,
          use_credits: useCredits,
        };

        const response = await claimReservation(
          auth.token,
          reservation!.restaurant.id,
          directLink.uuid!,
          claimBodyWithCredits,
        );
        if (response.reservation) {
          navigate(`../confirmation/`);
        }
      } catch (e: unknown) {
        if (
          e instanceof ApiError &&
          (e.type === 'ReserveErrorResponse' || e.type === 'CardErrorResponse')
        ) {
          updateDirectLink({
            payment_error: e.message,
          });

          navigate(`../payment/`);
        } else if (
          e instanceof ApiError &&
          (e.type === 'ReservationUnavailableErrorResponse' ||
            e.type === 'ReservationResourceNotFound' ||
            e.type === 'AlreadyCanceledReservationErrorResponse')
        ) {
          navigate(`../expired/`);
        } else if (
          e instanceof ApiError &&
          (e.type === 'ProcessingPaymentErrorResponse')
        ) {
          navigate(`../processing_payment/`);
        } else if (
          e instanceof ApiError &&
          e.type === 'ActionRequiredResponse'
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const result = await stripe?.confirmCardPayment(
            e.response.payment_intent_client_secret
          );

          if (result?.error) {
            updateDirectLink({
              payment_error: result.error.message,
            });

            console.error(result?.error.message);
            Sentry.captureException(result?.error);
          } else {
            navigate(`../confirmation/`);
          }
        } else if (e instanceof Error) {
          console.error(e);
          Sentry.captureException(e);
        }
      } finally {
        setBooking(false);
      }
    };
    if (!directLink.policy_accepted) {
      updateDirectLink({
        policy_accepted: false,
      });
      setBooking(false);
    } else {
      void go();
    }
  };
  const onAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      updateDirectLink({
        policy_accepted: true,
      });
    } else {
      updateDirectLink({
        ...directLink,
        policy_accepted: null,
      });
    }
  };

  const onMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      updateDirectLink({
        ...directLink,
        opt_in_accepted: 1,
      });
    } else {
      updateDirectLink({
        ...directLink,
        opt_in_accepted: 0,
      });
    }
  };

  const payment_method = methods_by_id[directLink.payment_method!];

  if (!reservation) {
    return <Header title="Sorry, no reservation" step={4} show_image={false} />;
  }

  function parseContent(content: string) {
    const split = content.split('\n');

    return (
      <>
        {split.map((line: string, i: number) => (
          <div className={styles.policycontent} key={i}>
            {line}
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <Header
        title="Confirm your booking"
        step={4}
        show_image={false}
        isEvent={reservation.restaurant.type === 'event'}
      />

      {directLink.policy_accepted === false && (
        <div className={clsx('content', styles.header_error)}>
          You are missing required fields.
        </div>
      )}

      <div className="content">
        {reservation && <h2>{reservation.restaurant?.name}</h2>}

        {reservation.restaurant.show_merchandising_price_totals && (
          <>
            {(reservation.restaurant.show_merchandising_tax_and_tip ||
              reservation.credits_used__currency ||
              reservation.points_used_currency_value__currency) && <hr />}
            {reservation.restaurant.show_merchandising_tax_and_tip ? (
              <>
                <div className={styles.payment}>
                  <div>
                    {reservation.table_type
                      ? reservation.table_type
                      : 'Subtotal'}
                  </div>
                  <div className={styles.paymentnumber}>
                    {reservation.total_prepayment_amount__currency}
                  </div>
                </div>
                {reservation.tax_rate > 0 && (
                  <div className={styles.payment}>
                    <div>Tax ({reservation.tax_rate}%)</div>
                    <div className={styles.paymentnumber}>
                      {reservation.tax_amount__currency}
                    </div>
                  </div>
                )}
                {reservation.service_charges__currency?.map(
                  (service, index) => (
                    <div key={index} className={styles.payment}>
                      <div>{service.label}</div>
                      <div className={styles.paymentnumber}>
                        {service.amount}
                      </div>
                    </div>
                  )
                )}
              </>
            ) : null}

            {reservation.credits_used__currency && (
              <div className={styles.payment}>
                <div>Credits</div>
                <div className={styles.paymentnumber}>
                  {reservation.credits_used__currency}
                </div>
              </div>
            )}

            {reservation.points_used_currency_value__currency && (
              <div className={styles.payment}>
                <div>Fun Coupons</div>
                <div className={styles.paymentnumber}>
                  {reservation.points_used_currency_value__currency}
                </div>
              </div>
            )}
          </>
        )}

        <hr />
        <div className={clsx('section', styles.payment_methods)}>
          <div>
            <h3>Total</h3>
            {reservation.total_paid > 0 && (
              <div className={styles.method}>Payment Method</div>
            )}
          </div>
          <div>
            <h3 className={styles.amount}>
              {reservation.total_paid__currency}
            </h3>
            {reservation.total_paid > 0 && (
              <div className={styles.card_info}>
                {payment_method ? (
                  <>
                    {payment_method.number}
                    <Card name={payment_method.brand as CardNames} />
                  </>
                ) : (
                  <div>Error</div>
                )}
              </div>
            )}
          </div>
        </div>

        <hr />

        {creditsAvailable && (
          <div className={styles.checkboxwrapper}>
            <input
                id="wallet"
                type="checkbox"
                onChange={(e) => {
                  void onConfirm(e.target.checked);
                }}
                checked={useCredits}
            />
            <div>
              <label htmlFor="wallet">
                Use Dorsia Wallet
              </label>
            </div>
          </div>
        )}

        <div className={clsx('section', styles.policy)}>
          {reservation.restaurant.merchandising_policy_title ? (
            <h3>{reservation.restaurant.merchandising_policy_title}</h3>
          ) : (
            <h3>Cancellation and modification policy</h3>
          )}

          <div className={styles.policywrapper}>
            {reservation.restaurant.merchandising_policy ? (
              <>{parseContent(reservation.restaurant.merchandising_policy)}</>
            ) : (
              <>
                <p>
                  Reservations are bookable 30 days in advance and can be
                  canceled up to 8 hours before the start time for a refund
                  minus a 10% processing fee. Any cancellations made within 8
                  hours of the reservation time are non-refundable. Once a
                  reservation is booked, the minimum spend per person remains
                  the same even if the party size decreases. Requests to
                  increase a party size can be made to info@dorsia.com, but are
                  not guaranteed.
                </p>
              </>
            )}
          </div>

          {reservation.restaurant.merchandising_marketing_opt_in_label ? (
            <div className={styles.checkboxwrapper}>
              <div className={styles.checkmarksection}>
                <input id="marketing" type="checkbox" onChange={onMarketing} />
                <span className={styles.checkmark}></span>
              </div>
              <div>
                <label htmlFor="marketing">
                  {reservation.restaurant.merchandising_marketing_opt_in_label}
                </label>
              </div>
            </div>
          ) : null}

          <div className={styles.checkboxwrapper}>
            <div className={styles.checkmarksection}>
              <input id="understood" type="checkbox" onChange={onAccept} />
              <span className={styles.checkmark}></span>
            </div>
            {reservation.restaurant.merchandising_policy_opt_in_label ? (
              <div>
                <label htmlFor="understood">
                  {reservation.restaurant.merchandising_policy_opt_in_label}
                </label>
              </div>
            ) : (
              <div>
                <label htmlFor="understood">
                  I understand and accept Dorsia's cancellation and modification
                  policy and that my use of this service is subject to Dorsia's{' '}
                  <a href="https://api.dorsia.com/terms" target="_blank">
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a href="https://api.dorsia.com/privacy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
            )}
          </div>

          {directLink.policy_accepted === false && (
            <div className={styles.error}>
              You must confirm that you understand Dorsia's{` `}
              {reservation.restaurant.merchandising_policy_title ? (
                <>{reservation.restaurant.merchandising_policy_title}</>
              ) : (
                <>cancellation and modification policy</>
              )}
            </div>
          )}

          {directLink?.payment_error && (
            <div className={styles.error}>
              There was an issue with your payment method.{' '}
              {directLink.payment_error}
            </div>
          )}
        </div>
        <div className="section">
          <button className="nextButton" onClick={onNext} disabled={booking}>
            {booking ? 'Booking...' : 'Complete Booking'}
          </button>
        </div>
        <div className={styles.grid}>
          <div className="section">
            <h3>Reservation details</h3>

            <div>{reservation.reservation_at_tzd.format('ddd, MMMM D')}</div>
            <div>{reservation.reservation_time_pretty}</div>
            <div>{reservation.party_size} guests</div>
            <div>{reservation.table_type}</div>

            {reservation.restaurant.type !== 'event' ? (
              <>
                <div className={styles.minimum}>
                  {reservation.table_minimum__currency} total minimum
                </div>
                <div className={styles.minimum_pp}>
                  {reservation.price_per_person__currency} minimum per person
                </div>
              </>
            ) : (
              <div className="section">
                <h3>Your details</h3>

                <div>
                  {reservation.user.first_name} {reservation.user.last_name}
                </div>
                <div>{reservation.user.email}</div>

                <div className={styles.edit}>
                  <Link to={'../info'} className="lightButton">
                    Edit your details
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <RemainingTime />
      </div>
    </>
  );
};

export default Confirm;
