import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useNavigate } from 'react-router-dom';
import Card, { CardNames } from '../../../components/Card/Card.tsx';
import { getPhone } from '../../../lib/getPhone.ts';
import { PaymentMethodsByIdAtom, usePaymentMethods } from '../lib/state.ts';
import Header from '../Header/Header.tsx';
import { createReservation, getUserCredits } from '../../../lib/api.ts';
import { ApiError } from '../../../lib/ApiError.ts';
import {
  reloadRestaurantKey,
  ReservationAtom,
  useConfirmRestaurant,
  AuthAtom,
  useWidgetState,
  useResetState,
  useUserDetails,
} from '../lib/state.ts';
import styles from './Confirm.module.css';

const Confirm: React.FC = () => {
  usePaymentMethods();
  const navigate = useNavigate();
  const [booking, setBooking] = useState<boolean>(false);
  const setFinal = useSetAtom(ReservationAtom);
  const auth = useAtomValue(AuthAtom);
  const [widget, updateWidget] = useWidgetState();
  const user = useUserDetails();
  const resetState = useResetState();
  const confirmation = useConfirmRestaurant();
  const methods_by_id = useAtomValue(PaymentMethodsByIdAtom);
  const [creditsAvailable, setCreditsAvailable] = useState<boolean>(false);
  const [useCredits, setUseCredits] = useState<boolean>(true);

  useEffect(() => {
    void onCheckCredits();
  }, []);

  const onCheckCredits = async () => {
    const user_credits_response = await getUserCredits(auth!.token);
    if (!user_credits_response) {
      return;
    }

    const hasCredits = user_credits_response.data.credits.USD !== '$0.00';
    const hasPoints = user_credits_response.data.points > 0;

    setCreditsAvailable(hasCredits || hasPoints);
  };

  const onConfirm = (useCreditsValue: boolean) => {
    setUseCredits(useCreditsValue);

    const updatedWidget = {
      ...widget,
      confirm_body: {
        ...widget.confirm_body!,
        use_credits: useCreditsValue,
      },
    };

    updateWidget(updatedWidget);
  };

  const onNext = () => {
    const go = async () => {
      if (auth && widget.reserve_body) {
        setBooking(true);

        try {
          const response = await createReservation(
            auth.token,
            widget.reserve_body
          );
          if (response.reservation) {
            setFinal(response.reservation);
            resetState();
            navigate(`../confirmation`);
          }
        } catch (e: unknown) {
          if (
            e instanceof ApiError &&
            (e.type === 'ReserveErrorResponse' ||
              e.type === 'CardErrorResponse')
          ) {
            updateWidget({
              payment_error: e.message,
            });

            navigate(`../payment`);
          } else if (
            e instanceof ApiError &&
            (e.type === 'ReservationUnavailableErrorResponse' ||
              e.type === 'ReservationResourceNotFound' ||
              e.type === 'AlreadyCanceledReservationErrorResponse')
          ) {
            reloadRestaurantKey();
            updateWidget({
              availability: undefined,
              option: undefined,
              reservation_error: e.message,
            });

            // navigate(`../booking`);
          } else if (e instanceof Error) {
            console.error(e.message);
            Sentry.captureException(e);
            // navigate(`../booking`);
          }
        } finally {
          setBooking(false);
        }
      }
    };

    if (!widget.policy_accepted) {
      updateWidget({
        policy_accepted: false,
      });
      setBooking(false);
    } else {
      void go();
    }
  };

  const onMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      updateWidget({
        ...widget,
        opt_in_accepted: 1,
      });
    } else {
      updateWidget({
        ...widget,
        opt_in_accepted: 0,
      });
    }
  };

  const onAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      updateWidget({
        policy_accepted: true,
      });
    } else {
      updateWidget({
        policy_accepted: null,
      });
    }
  };

  const payment_method = methods_by_id[widget.payment_method!];
  const user_phone = getPhone(widget.phone_number, widget.phone_country);

  function parseContent(content: string) {
    const split = content.split('\n');

    return (
      <>
        {split.map((line: string, i: number) => (
          <div className={styles.policycontent} key={i}>
            {line}
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <Header title="Confirm your booking" step={4} show_image={false} />

      {widget.policy_accepted === false && (
        <div className={clsx('content', styles.header_error)}>
          You are missing required fields.
        </div>
      )}

      <div className="content">
        {confirmation && <h2>{confirmation.restaurant.name}</h2>}
        <>
          {(confirmation?.fee_restaurant === 0 ||
            confirmation?.restaurant?.show_merchandising_price_totals) &&
            (confirmation?.restaurant?.show_merchandising_tax_and_tip ||
              confirmation?.credits_used__currency) && (
              <div className={clsx('section')}>
                {confirmation?.restaurant?.show_merchandising_tax_and_tip ? (
                  <>
                    <hr />
                    <div className={clsx('section', styles.payment)}>
                      <div>
                        {confirmation.fee_restaurant === 0
                          ? 'Minimum spend'
                          : confirmation?.table_type
                          ? confirmation?.table_type
                          : 'Subtotal'}
                      </div>
                      <div className={styles.paymentnumber}>
                        {confirmation?.fee_restaurant__currency}
                      </div>
                    </div>
                    {confirmation?.tax_rate > 0 && (
                      <div className={clsx('section', styles.payment)}>
                        <div>Tax ({confirmation.tax_rate}%)</div>
                        <div className={styles.paymentnumber}>
                          {confirmation.tax_amount__currency}
                        </div>
                      </div>
                    )}

                    {confirmation.service_charges?.map((service, index) => (
                      <div
                        className={clsx('section', styles.payment)}
                        key={index}
                      >
                        <div>
                          {service.label}

                          {service.label === 'Cancellation Deposit' && (
                            <div className={styles.lineItemNote}>
                              (Applied to your final bill)
                            </div>
                          )}
                        </div>
                        <div className={styles.paymentnumber}>
                          {service.amount__currency}
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}

                {confirmation?.credits_used__currency && (
                  <div className={clsx('section', styles.payment)}>
                    <div>Credits</div>
                    <div className={styles.paymentnumber}>
                      {confirmation.credits_used__currency}
                    </div>
                  </div>
                )}
              </div>
            )}
        </>

        {((!!confirmation && confirmation?.total > 0) || !!payment_method) && (
          <>
            <div className={clsx('section', styles.payment_methods)}>
              <div>
                {!!confirmation && confirmation?.total > 0 && <h3>Total</h3>}

                {!!payment_method && (
                  <div className={styles.method}>Payment Method</div>
                )}

                {!!confirmation && confirmation?.total > 0 && (
                  <div className={styles.notices}>
                    {confirmation.notices.map((notice, i) => {
                      return <ReactMarkdown key={i}>{notice}</ReactMarkdown>;
                    })}
                  </div>
                )}
              </div>
              <div>
                {!!confirmation && confirmation?.total > 0 && (
                  <h3 className={styles.amount}>
                    {confirmation.total__currency}
                  </h3>
                )}

                {!!payment_method && (
                  <div className={styles.card_info}>
                    {payment_method.number}
                    <Card name={payment_method.brand as CardNames} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <hr />

        {creditsAvailable && (
          <div className={styles.checkboxwrapper}>
            <input
              id="wallet"
              type="checkbox"
              onChange={(e) => {
                void onConfirm(e.target.checked);
              }}
              checked={useCredits}
            />
            <div>
              <label htmlFor="wallet">Use Dorsia Wallet</label>
            </div>
          </div>
        )}

        <div className={clsx('section', styles.policy)}>
          {!!confirmation && confirmation?.total > 0 ? (
            <>
              {confirmation?.restaurant.merchandising_policy_title ? (
                <h3>{confirmation?.restaurant.merchandising_policy_title}</h3>
              ) : (
                <h3>Cancellation and modification policy</h3>
              )}

              {confirmation?.restaurant.merchandising_policy ? (
                <div className={styles.policywrapper}>
                  {parseContent(confirmation?.restaurant.merchandising_policy)}
                </div>
              ) : null}

              {confirmation?.fee_restaurant &&
              confirmation?.fee_restaurant > 0 &&
              widget?.confirm_body?.restaurant_id !== 794 &&
              widget?.confirm_body?.restaurant_id !== 172 ? (
                <div className={styles.policywrapper}>
                  <p>
                    Reservations are bookable 30 days in advance and can be
                    canceled up to 8 hours before the start time for a refund
                    minus a 10% processing fee. Any cancellations made within 8
                    hours of the reservation time are non-refundable. Once a
                    reservation is booked, the minimum spend per person remains
                    the same even if the party size decreases. Requests to
                    increase a party size can be made to info@dorsia.com, but
                    are not guaranteed.
                  </p>
                </div>
              ) : (
                <>
                  {confirmation?.cancellation_policy ? (
                    <div className={styles.policywrapper}>
                      {parseContent(confirmation.cancellation_policy)}
                    </div>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <></>
          )}

          {confirmation?.restaurant.merchandising_marketing_opt_in_label ? (
            <div className={styles.checkboxwrapper}>
              <div className={styles.checkmarksection}>
                <input id="marketing" type="checkbox" onChange={onMarketing} />
                <span className={styles.checkmark}></span>
              </div>
              <div>
                <label htmlFor="marketing">
                  {confirmation.restaurant.merchandising_marketing_opt_in_label}
                </label>
              </div>
            </div>
          ) : null}

          <div className={styles.checkboxwrapper}>
            <div className={styles.checkmarksection}>
              <input id="understood" type="checkbox" onChange={onAccept} />
              <span className={styles.checkmark}></span>
            </div>
            {confirmation?.restaurant.merchandising_policy_opt_in_label ? (
              <div>
                <label htmlFor="understood">
                  {confirmation.restaurant.merchandising_policy_opt_in_label}
                </label>
              </div>
            ) : (
              <div>
                <label htmlFor="understood">
                  I understand and accept Dorsia's cancellation and modification
                  policy and that my use of this service is subject to Dorsia's{' '}
                  <a href="https://api.dorsia.com/terms" target="_blank">
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a href="https://api.dorsia.com/privacy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
            )}
          </div>

          {widget.policy_accepted === false && (
            <div className={styles.error}>
              You must confirm that you understand Dorsia's cancellation and
              modification policy
            </div>
          )}

          <button className="nextButton" onClick={onNext} disabled={booking}>
            {booking ? 'Booking...' : 'Complete Booking'}
          </button>
        </div>
        <div className={styles.grid}>
          <div className="section">
            <h3>Reservation details</h3>

            {confirmation ? (
              <>
                <div>{dayjs(confirmation.date_full).format('ddd, MMMM D')}</div>
                <div>{confirmation.time}</div>
                <div>{confirmation.party} guests</div>
                <div>{confirmation.table_type}</div>

                {!!confirmation && confirmation.total > 0 && (
                  <>
                    {confirmation.fee_restaurant === 0 ? (
                      <div className={styles.minimum}>Minimum spend</div>
                    ) : (
                      <>
                        <div className={styles.minimum}>
                          {confirmation.fee_restaurant__currency} total minimum
                        </div>
                        <div className={styles.minimum_pp}>
                          {confirmation.price_per_person__currency} minimum per
                          person
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <div>Loading...</div>
            )}

            <div className={styles.edit}>
              <Link to={'../'} className="lightButton">
                Edit booking
              </Link>
            </div>
          </div>

          <div className="section">
            <h3>Your details</h3>

            <div>
              {user.first_name} {user.last_name}
            </div>
            <div>{!!user_phone && user_phone.formatNational()}</div>
            <div>{user.email}</div>

            <div className={styles.edit}>
              <Link to={'../info'} className="lightButton">
                Edit your details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;
